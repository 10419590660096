<template lang="pug">
  div
    v-row
      v-col(cols="12").mt-5.mb-0.pb-0
        v-autocomplete(
          label="Parent Code"
          :items="parentCodes"
          item-value="id"
          item-text="account_code"
          v-model="parentCode"
          return-object
          dense
        )
          template(v-slot:item="{ item }")
            span.primary--text.mr-2 {{ item.account_code }}
            small.blue-grey--text {{ item.description }}
      v-col(cols="12").mb-0.pb-0
        v-autocomplete(
          label="Account Code"
          :items="accountCodes"
          dense
          item-value="id"
          item-text="account_code"
          v-model="accountCodeId"
          :error-messages="voucherDetailStorePostErrors.account_code_id"
        )
          template(v-slot:item="{ item }")
            span.primary--text.mr-2 {{ item.account_code }}
            small.blue-grey--text {{ item.description }}
      v-col(cols="12").mb-0.pb-0
        v-text-field(
          label="Amount"
          type="number"
          dense
          v-model.number="amount"
          :error-messages="voucherDetailStorePostErrors.amount"
        )
      v-col(cols="12").mb-0.pb-0
        v-textarea(
          label="Reason" 
          v-model="reason" 
          dense
          outlined no-resize
          :error-messages="voucherDetailStorePostErrors.reason"
        )
      v-col(cols="12").mb-0.pb-0
        v-btn(
          v-if="!editMode"
          color="success"
          dense
          small
          text
          @click="store"
          :loading="voucherDetailStorePosting"
        )
          span Add
        div(v-else)
          v-btn(
            color="yellow darken-3"
            dense
            small
            text
            @click="update"
            :loading="voucherDetailStorePosting"
          )
            span Update
          v-btn(
            color="error"
            dense
            small
            text
            @click="editMode = false"
          )
            span Cancel
</template>
<script>
import accountCodeRepository from '@/repositories/account-code.repository'
import voucherDetailRepository from '@/repositories/voucher-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [accountCodeGetVars, accountCodeGetVarNames] = requestVars({ identifier: 'account-code' })
const [voucherDetailStoreVars, voucherDetailStoreVarNames] = requestVars({ identifier: 'voucher-detail-store', request: 'post', hasData: false })

const accountCodeGetHandler = new VueRequestHandler(null, accountCodeGetVarNames)
const voucherDetailStoreHandler = new VueRequestHandler(null, voucherDetailStoreVarNames)

const inputVars = () => ({
  id: null,
  accountCodeId: null,
  amount: null,
  reason: null,
})

export default {
  name: 'EditVoucherDetails',
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ...inputVars(),
      ...accountCodeGetVars,
      ...voucherDetailStoreVars,
      parentCode: {},
    }
  },
  computed: {
    editMode: {
      get () {
        return !this.$objectEmpty(this.defaultData)
      },
      set (val) {
        if (val) return
        this.$emit('update:default-data', {})
      },
    },
    accountCode () {
      return this.accountCodes.find(item => item.id === this.accountCodeId)
    },
    parentCodes () {
      return this.accountCodeList.filter(item => !item.parent_id)
    },
    accountCodes () {
      const filterAccountCodes = item => {
        if (!item.parent_id) return false
        if (this.parentCode.id && item.parent_id !== this.parentCode.id) return false
        return true
      }
      return this.accountCodeList.filter(filterAccountCodes)
    },
    voucherId () {
      return this.$route.params.voucherId
    },
  },
  watch: {
    defaultData () {
      const input = inputVars()
      this.$populateInputData({ input })
    },
  },
  created () {
    this.getAccountCodes()
  },
  methods: {
    getAccountCodes () {
      const handler = accountCodeGetHandler
      const repository = accountCodeRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    async store () {
      const handler = voucherDetailStoreHandler
      const repository = voucherDetailRepository.store
      const confirm = await this.storeConfirm()
      if (!confirm) return
      const { voucherId } = this
      const defaults = inputVars()
      const { id, ...data } = this.$getInputData({ ...defaults, voucherId })
      handler.setVue(this)
      handler.execute(repository, [data], this.$resetInputData(defaults))
    },
    async update () {
      const handler = voucherDetailStoreHandler
      const repository = voucherDetailRepository.update
      const confirm = await this.updateConfirm()
      if (!confirm) return
      const { id, ...data } = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data], () => { this.editMode = false })
    },
    storeConfirm () {
      const config = {
        title: 'Add',
        color: 'green',
        icon: 'mdi-cart-variant',
      }
      return this.$confirm('Are you sure you want to add this item?', config)
    },
    updateConfirm () {
      const config = {
        title: 'Update',
        color: 'yellow darken-3',
        icon: 'mdi-pencil',
      }
      return this.$confirm('Are you sure you want to update this item?', config)
    },
  },
}
</script>